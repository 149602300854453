import styled from '@emotion/styled'
import { rem } from 'polished'
import { css } from '@emotion/core'

import { WidthContainer } from '../../utils/styled'

export const Header = styled.header`
  display: none;
  position: relative;
  z-index: 1;

  nav {
    a {
      color: ${({ theme, dark }) =>
        dark ? theme.colors.light.base.hex : 'inherit'};
      display: inline-block;
      text-decoration: none;
      line-height: 1;
    }
  }

  a {
    color: ${({ theme, dark }) =>
      dark ? theme.colors.light.base.hex : 'inherit'};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: block;
  }
`

export const TopNav = styled.nav`
  a {
    border-left: 1px solid ${({ theme }) => theme.colors.dark.base.hex};
    padding: 0 1em;

    &:first-of-type {
      padding-left: 0;
      border-left: none;
    }
  }
`

export const TopBar = styled.div`
  ${WidthContainer};

  font-size: ${({ theme }) => rem(theme.fonts.body.xs.size)};
  padding-top: ${({ theme }) => theme.spacing.xxs};
  padding-bottom: ${({ theme }) => theme.spacing.xxs};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .links {
    margin-left: auto;
    margin-right: 0;
  }

  .links a {
    padding: 0 7px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .special-links a {
    background-color: #7eb847;
    color: #fff;
    text-decoration: none;
    margin: 0 3px;

    &:hover {
      color: ${({ theme }) => theme.colors.action.base.hex};
      text-decoration: none;
      background-color: #fff;
    }
  }
`

export const BottomBar = styled.div`
  ${WidthContainer};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LocationChanger = styled.div`
  display: flex;

  a {
    border-left: 1px solid ${({ theme }) => theme.colors.dark.base.hex};
    color: inherit;
    margin-left: 1em;
    padding-left: 1em;

    .ci {
      color: blue;
    }
  }

  span {
    color: red;
  }
`

export const MainNav = styled.nav`
  min-height: ${({ theme }) => theme.spacing.xl};

  display: flex;
  align-items: stretch;

  .logo {
    padding-top: ${({ theme }) => theme.spacing.min};
    padding-bottom: ${({ theme }) => theme.spacing.min};
    padding-right: ${({ theme }) => theme.spacing.sm};
    padding-left: 0;
    align-self: center;
    flex-shrink: 0;
  }

  .primary-link {
    display: flex;
    justify-content: flex-start;
    position: relative;
    transition: color 0.1s, background-color 0.1s;

    > span {
      padding: ${({ theme }) => `0 ${theme.spacing.xs}`};
      align-self: center;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.base.hex};
      color: ${({ theme }) => theme.colors.light.base.hex};
    }

    &:hover .subnav {
      display: flex;
    }
  }

  .subnav {
    box-shadow: ${({ theme }) => theme.shadow};
    display: none;
    position: absolute;
    top: 100%;
    left: 0%;
    flex-direction: column;
    width: 251px;
    list-style: none;
    background: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;

    a {
      padding: ${({ theme }) => theme.spacing.sm};
      padding-right: ${({ theme }) => theme.spacing.xl};
      color: ${({ theme }) => theme.colors.dark.base.hex};
      display: block;
      cursor: pointer;
      transition: color 0.25s, background-color 0.25s;
      text-transform: initial;

      &:hover {
        background-color: ${({ theme }) => theme.colors.light.dark.hex};
      }
    }
  }
`

export const CallButton = styled.a(
  ({ theme }) => css`
    color: ${theme.colors.action.base.hex};
    text-decoration: none;
  `
)

export const MobileHeader = styled.header(
  ({ theme, dark }) => css`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0 ${theme.spacing.sm};

    svg {
      margin: 0 0 0 10px;
      flex: 0 1 auto;
      align-self: center;
      width: 30px;
      height: 30px;
      color: ${theme.colors.dark.base.hex};
      border: 1px solid ${theme.colors.dark.base.hex};
      width: 25px;
      height: 25px;
      padding: 3px;
      border-radius: 18px;
    }

    .logo {
      flex: 1 1 auto;
      align-self: center;
      padding-top: ${theme.spacing.xxs};
      padding-bottom: ${theme.spacing.xxs};

      img {
        width: 68px;
      }
    }

    @media (min-width: ${theme.breakpoints.lg}) {
      display: none;
    }
  `
)

export const MobileNav = styled.nav`
  padding: ${({ theme }) => `${theme.spacing.md}`};
  z-index: 1;
  background-color: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;

  > button {
    background: transparent;
    border: none;
  }

  > a,
  button {
    padding-left: ${({ theme }) => theme.spacing.sm};
    color: ${({ theme }) => theme.colors.dark.base.hex};
    border-bottom: ${({ theme }) => `solid 1px ${theme.colors.light.dark.hex}`};
    text-decoration: none;
    transition: background-color 0.3s;

    &:last-child {
      border-bottom: 0;
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.primary.base.hex};
      color: ${({ theme }) => theme.colors.light.base.hex};
    }

    p {
      display: flex;
      align-items: center;
    }

    svg {
      margin: 0;
    }
  }

  .back-button {
    background-color: ${({ theme }) => theme.colors.light.dark.hex};
  }
`
