import React from 'react'
import PropTypes from 'prop-types'

// eslint-disable-next-line import/no-named-default
import { default as CoreLink } from './Link/index'

const Link = ({ children, to, ...props }) => {
  const externalLinks = [
    '/for-homes/',
    '/for-homes',
    '/residential-plans',
    '/residential-plans/',

  ]

  function handleClick(e) {
    /*
      For some reason the enrollment angular app will NOT work if we link to it internaly with gatsby link.
      For this reason, we need to detect if the user is navingating to the enrollment page,
      and manually send them there so they get a fresh page load.
    */
    if (
      (e.type === 'click' || e.key === 'Enter') &&
      externalLinks.includes(to)
    ) {
      e.preventDefault()

      // eslint-disable-next-line no-undef
      window.location =
        to.charAt(to.length - 1) === '/' ? `${to}#/` : `${to}/#/`
    }
  }

  // Use a normal anchor tag for links to the enrollment pages to disable pre-loading, which seems to cause issues
  // in Chrome incognito windows.
  return externalLinks.includes(to) ? (
    <a {...props} href={to} onClick={handleClick} onKeyPress={handleClick}>
      {children}
    </a>
  ) : (
      <CoreLink {...props} to={to} onClick={handleClick} onKeyPress={handleClick}>
        {children}
      </CoreLink>
    )
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
}

export default Link
