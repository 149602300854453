import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { stripUnit } from 'polished'

import fluidRange from '../../utils/fluidRange'
import { WidthContainer } from '../../utils/styled'

export const Section = styled.section(
  WidthContainer,
  ({ theme, prominent }) => css`
  header{
    width: 100%;

    h2, h3{
      text-align: center;
    }

    div{
      text-align: left;
    }
  }
    ${prominent &&
    `
    color: ${theme.colors[theme.prominent].base.readable};
    background-color: ${theme.colors[theme.prominent].base.hex} !important;

    * {
      color: ${theme.colors[theme.prominent].base.readable};
    }
  `}
    ${fluidRange('padding-top', [
      stripUnit(theme.spacing.lg),
      stripUnit(theme.spacing.max),
    ])};
    ${fluidRange('padding-bottom', [
      stripUnit(theme.spacing.lg),
      stripUnit(theme.spacing.max),
    ])};
  `
)