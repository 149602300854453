import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ChevronLeft, ChevronRight } from 'emotion-icons/material'

import Typography from '../Typography'
import Link from '../Link'
import * as Styled from './Header.styles'

const MobileNav = ({ headerNavItems, isMobileNavOpen }) => {
  const [isSubnavOpen, setSubnavOpen] = useState(false)
  const [subnav, setSubnav] = useState([])

  const storeSubnav = index => {
    setSubnavOpen(true)
    setSubnav(headerNavItems[index].subnav)
  }

  if (!isMobileNavOpen) {
    return null
  }

  if (isSubnavOpen) {
    return (
      <Styled.MobileNav>
        <button
          type="button"
          className="back-button"
          onClick={() => setSubnavOpen(false)}
        >
          <Typography>
            <ChevronLeft />
            Back to Main
          </Typography>
        </button>
        {subnav.map(item => (
          <Link key={item.title} to={item.url}>
            <Typography>
              {item.title}
              <ChevronRight />
            </Typography>
          </Link>
        ))}
      </Styled.MobileNav>
    )
  }

  return (
    <Styled.MobileNav>
      {headerNavItems.map((item, index) =>
        typeof item.subnav === 'undefined' ? (
          <Link key={item.title} to={item.url}>
            <Typography>
              {item.title}
              <ChevronRight />
            </Typography>
          </Link>
        ) : (
          <button
            type="button"
            key={item.title}
            onClick={() => storeSubnav(index)}
          >
            <Typography>
              {item.title}
              <ChevronRight />
            </Typography>
          </button>
        )
      )}
    </Styled.MobileNav>
  )
}

MobileNav.propTypes = {
  headerNavItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobileNavOpen: PropTypes.bool.isRequired,
}

export default MobileNav
