import React from 'react'
import PropTypes from 'prop-types'
import { Phone } from 'emotion-icons/material'
import _get from 'lodash.get'
import Button from '../Button'
import * as Styled from './PhoneCTA.styles'

import { useMapi } from '../../context/mapi'
import { gaEventTracker, getPageTitle } from '../GaEventTrackerHelper'

const PhoneCTA = ({
  phoneNumber: passedPhoneNumber,
  children,
  variant,
  rounded,
  shadow,
  outlined,
  type,
  color,
  className,
  ...props
}) => {
  let phoneNumber = passedPhoneNumber

  if (!phoneNumber) {
    const data = useMapi()
    phoneNumber = _get(data, 'rotatedNumber')
  }

  const pagetitle = getPageTitle();

  return (
    <Styled.Anchor href={`tel:${phoneNumber}`}>
      <Button
        // IE needs the size attribute to avoid "height: auto;" issues
        leftIcon={<Phone size="32px" />}
        variant={variant}
        rounded={rounded}
        shadow={shadow}
        outlined={outlined}
        type={type}
        color={color}
        className={className}
        {...props}
        onClick={() => gaEventTracker("Header - TFN - CTA", "Click", pagetitle)}
      >
        {children} {phoneNumber}
      </Button>
    </Styled.Anchor>
  )
}

PhoneCTA.defaultProps = {
  rounded: 'min',
  shadow: true,
  outlined: false,
  type: 'button',
  color: 'primary',
  className: null,
  variant: null,
  phoneNumber: '',
}

PhoneCTA.propTypes = {
  children: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'small', 'feature', 'hero', 'mega']),
  rounded: PropTypes.oneOf(['none', 'min', 'max']),
  shadow: PropTypes.bool,
  outlined: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'dark', 'light']),
  className: PropTypes.string,
}

export default PhoneCTA
