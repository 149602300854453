import styled from '@emotion/styled'
import Section from '../Section'

export const Footer = styled.footer`
  ${({ theme, dark }) =>
    dark &&
    `
    background-color: ${theme.colors.dark.base.hex};
    color: ${theme.colors.dark.base.readable};
  `};
`

export const TopSection = styled(Section)`
  padding-top: ${({ theme }) => theme.spacing.lg} !important;
  padding-bottom: ${({ theme }) => theme.spacing.lg} !important;
  border-bottom: 1px solid
    ${({ theme, dark }) =>
    dark ? theme.colors.dark.light.hex : theme.colors.light.dark.hex};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    a.logo{
      display: block;
      text-align: center;
    }
  }  
`

export const BottomSection = styled(Section)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(132px, 1fr));
  padding-top: ${({ theme }) => theme.spacing.lg} !important;
  padding-bottom: ${({ theme }) => theme.spacing.lg} !important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.dark.light.hex};
`

export const ExtraLinks = styled(Section)`
  padding-top: ${({ theme }) => theme.spacing.lg} !important;
  padding-bottom: ${({ theme }) => theme.spacing.lg} !important;

  nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    nav {
      grid-template-columns: repeat(6, auto);
    }
  }
`

export const LegalSection = styled(Section)`
  padding-top: ${({ theme }) => theme.spacing.lg} !important;
  padding-bottom: ${({ theme }) => theme.spacing.lg} !important;
  background-color: ${({ theme, dark }) =>
    dark ? theme.colors.dark.darker.hex : theme.colors.light.dark.hex};
`

export const Nav = styled.nav`
  a {
    display: block;
    padding-bottom: 1em;
    color: inherit;
    text-decoration: none;
  }
`

export const SocialLinks = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sm};

  a {
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }
`
export const CoronaBar = styled.div`
  width: 100%;
  text-align: center;
  padding: 15px;
  background-color: #fca500;
  color: #fff;
  font-weight: bold;

  a {
    color: #fff;
    text-decoration: underline;

    :hover {
      color: #42535c;
    }
  }
`
