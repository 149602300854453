/* eslint-disable no-undef */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Menu, KeyboardArrowDown, Phone, Person } from 'emotion-icons/material'
import Helmet from 'react-helmet'
import Link from '../Link'
import PhoneCTA from '../PhoneCTA'
import MobileNav from './MobileNav'
import * as Styled from './Header.styles'
import primaryLogo from '../../images/primary-logo.svg'

import favicon from './favicon.png'
import { gaEventTracker, getPageTitle } from '../GaEventTrackerHelper'

const headerNavItems = [
  {
    title: 'Home Energy',
    subnav: [
      { title: 'Home Energy', url: '/home-energy' },
      { title: 'Residential Plans', url: '/home-energy/plans' },
    ],
  },
  {
    title: 'Commercial Energy',
    subnav: [
      {
        title: 'Commercial Energy',
        url: '/commercial-energy',
      },
      {
        title: 'LED Retrofit',
        url: '/commercial-energy/benefits-of-led-retrofits',
      },
      {
        title: 'Smart Building Controls',
        url: '/commercial-energy/smart-building-controls',
      },
      {
        title: 'Commercial Energy Rates',
        url: '/commercial-energy/commercial-energy-rates',
      },
      { title: 'Affinity Program', url: '/commercial-energy/affinity-program' },
      {
        title: 'Energy Management',
        url: '/commercial-energy/why-is-energy-management-important',
      },
      {
        title: 'HVAC Controls',
        url: '/commercial-energy/hvac-controls',
      },
      {
        title: 'Lighting Control',
        url: '/commercial-energy/lighting-control',
      },
      {
        title: 'Energy Monitoring',
        url: '/commercial-energy/what-is-energy-monitoring',
      },
      {
        title: 'Energy Analytics',
        url: '/commercial-energy/energy-analytics-benefits-and-how-it-works',
      },
      {
        title: 'Advanced Solutions',
        url: '/commercial-energy/advanced-solutions',
      },
    ],
  },
  { title: 'Locations', url: '/locations' },
  {
    title: 'Learning Center',
    subnav: [
      {
        title: 'Learning Center',
        url: '/learning-center',
      },
      {
        title: 'Green Energy',
        url: '/green-energy',
      },
      {
        title: 'Energy Deregulation',
        url: '/learning-center/deregulation',
      },
      {
        title: 'Electricity',
        url: '/learning-center/electricity',
      },
      {
        title: 'Glossary of Terms',
        url: '/learning-center/glossary-of-terms',
      },
      {
        title: 'Natural Gas',
        url: '/learning-center/natural-gas',
      },
      {
        title: 'Prices & Products',
        url: '/learning-center/prices-and-products',
      },

      {
        title: 'Price Volatility',
        url: '/learning-center/price-volatility',
      },
      {
        title: 'Scam Avoidance',
        url: '/learning-center/scam-avoidance',
      },
    ],
  },
  { title: 'Blog', url: '/blog/' },
]

const Header = ({ dark, ...props }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const pagetitle = getPageTitle();
  return (
    <>
      <Helmet>
        <link rel="icon" href={favicon} type="image/x-icon" />

        <script>{`
          (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"17438683"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`
        }
        </script>
        
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-801519743"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];   
          function gtag(){dataLayer.push(arguments);}   
          gtag('js', new Date());gtag('config', 'AW-801519743');
        `}</script>

        <script type="text/javascript">
          {`
          (function (i, s, o, g, r, a, m) {
            i["GoogleAnalyticsObject"] = r;
            (i[r] =
              i[r] ||
              function () {
                (i[r].q = i[r].q || []).push(arguments);
              }),
              (i[r].l = 1 * new Date());
            (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
          })(
            window,
            document,
            "script",
            "//www.google-analytics.com/analytics.js",
            "ga"
          );
          ga("create", "UA-8681512-2", "auto");
          ga("require", "displayfeatures");
          ga("require", "linkid", "linkid.js");
          ga("send", "pageview");
          ga("require", "ecommerce");
          `}
        </script>
      </Helmet>
      <Styled.Header role="banner" dark={dark} {...props}>
        <Styled.TopBar padTop={false} padBottom={false}>
          <div className="links">
            <Link to="/">EN</Link>
            <Link to="/es">ES</Link>
            <span className="special-links">
              <Link to="https://help.justenergy.com/s/?language=en_US">
                Customer Care
              </Link>
              <Link to="https://www.justenergy.com/myaccount">My Account</Link>
            </span>
          </div>
        </Styled.TopBar>
        <Styled.BottomBar padTop={false} padBottom={false}>
          <Styled.MainNav role="navigation" dark={dark}>
            <Link to="/" className="logo">
              <img src={primaryLogo} alt="JustEnergy Logo" />
            </Link>
            {headerNavItems.map(item =>
              typeof item.subnav === 'undefined' ? (
                <span key={item.title} className="primary-link">
                  <span>
                    <Link to={item.url}>{item.title}</Link>
                  </span>
                </span>
              ) : (
                  <span key={item.title} className="primary-link">
                    <span>
                      {item.title} <KeyboardArrowDown size={18} />
                    </span>
                    <ul className="subnav">
                      {item.subnav.map(subitem => (
                        <li>
                          <Link to={subitem.url}>{subitem.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </span>
                )
            )}
          </Styled.MainNav>
          <PhoneCTA>Order</PhoneCTA>
        </Styled.BottomBar>
      </Styled.Header>

      <Styled.MobileHeader>
        <Link to="/" className="logo">
          <img src={primaryLogo} alt="JustEnergy Logo" />
        </Link>

        <Link to="/myaccount"><Person /></Link>

        <a to="tel:18662883105" target="_self" onClick={() => gaEventTracker("Header - TFN - CTA", "Click", pagetitle)}><Phone /> </a>


        <Menu onClick={() => setMobileNavOpen(!isMobileNavOpen)} id="hamburgerMenu" />


      </Styled.MobileHeader>

      <MobileNav
        headerNavItems={headerNavItems}
        isMobileNavOpen={isMobileNavOpen}
      />

    </>
  )
}

Header.defaultProps = {
  topBarNavItems: {},
  dark: false,
}

Header.propTypes = {
  dark: PropTypes.bool,
  headerNavItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  topBarNavItems: PropTypes.arrayOf(PropTypes.object),
}

export default Header 