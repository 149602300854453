import { css } from '@emotion/core'
import { stripUnit } from 'polished'

import fluidRange from './fluidRange'

export const WidthContainer = ({ theme }) => css`
  ${fluidRange('padding-left', [
    stripUnit(theme.spacing.lg),
    stripUnit(theme.spacing.xl),
  ])};
  ${fluidRange('padding-right', [
    stripUnit(theme.spacing.lg),
    stripUnit(theme.spacing.xl),
  ])};

  @media (min-width: ${`${stripUnit(theme.breakpoints.xl) +
      stripUnit(theme.spacing.max)}px`}) {
    padding-right: ${`calc((100% - ${theme.breakpoints.xl}) / 2)`};
    padding-left: ${`calc((100% - ${theme.breakpoints.xl}) / 2)`};
  }
`
