/* eslint-disable no-undef */
import React from 'react'
import Link from '../Link'
import Typography from '../Typography'
import * as Styled from './Footer.styles'
import secondaryLogo from '../../images/secondary-logo.svg'
import linkedIn from '../../images/icon-linkedin.svg'
import youTube from '../../images/icon-youtube.svg'
import twitter from '../../images/icon-twitter.svg'
import faceBook from '../../images/icon-facebook.svg'
import googlePlay from '../../images/google-play.svg'
import appStore from '../../images/app-store.svg'

const footerNavItems = [
  {
    label: 'About Us',
    navItems: [
      {
        text: 'About Just Energy',
        href: '/about-us',
      },
      {
        text: 'Blog',
        href: '/blog',
      },
      {
        text: 'Our Markets',
        href: '/about-us/our-markets',
      },
      {
        text: 'Investors',
        href: 'https://investors.justenergy.com/',
      },
      {
        text: 'Careers',
        href: '/about-us/career-center',
      },
    ],
  },
  {
    label: 'JustEnergy.com',
    navItems: [
      {
        text: 'Home Energy',
        href: '/for-homes',
      },
      {
        text: 'Commercial Energy',
        href: '/commercial-energy',
      },
      {
        text: 'Affinity Programs',
        href: '/commercial-energy/affinity-program',
      },
    ],
  },
  {
    label: 'Discover',
    navItems: [
      {
        text: 'Mobile App',
        href: '/app',
      },
      {
        text: 'Locations',
        href: '/locations',
      },
    ],
  },
  {
    label: 'Need Help?',
    navItems: [
      {
        text: 'Customer Service',
        href: '/customer-service',
      },
      {
        text: 'Learning Center',
        href: '/learning-center',
      },
      {
        text: 'Report an Outage',
        href: '/report-an-outage',
      },
      {
        text: 'Contact Us',
        href: 'https://help.justenergy.com/s/contact-us',
      },
    ],
  },
]

const additionalFooterLinks = [
  {
    text: 'Privacy Policy',
    href: '/legal/privacy-policy',
  },
  {
    text: 'Terms of Use',
    href: '/legal/terms-of-use',
  },
  {
    text: 'Accessibility',
    href: '/legal/accessibility',
  },
  {
    text: 'Sitemap',
    href: '/html-sitemap',
  },
  {
    text: 'TX Customers',
    href: '/legal/tx-customers',
  },
  {
    text: 'CA Customers',
    href: '/legal/ca-customers',
  },
  {
    text: 'Do Not Sell My Info',
    href: '/ccpa',
  },
  {
    text: 'IL Customers',
    href: '/legal/il-customers',
  },
  {
    text: 'MA Customers',
    href: '/legal/ma-customers',
  },
  {
    text: 'NJ Customers',
    href: '/legal/nj-customers',
  },
  {
    text: 'Canada Customers',
    href: '/legal/canada-customers',
  },
]

let d = new Date();
let y = d.getFullYear();

const disclaimers = [
  '© ' + y + ' Just Energy - 5251 Westheimer Rd. Suite 1000, Houston, TX 77056',
  'JUST ENERGY and the JUST ENERGY design are trademarks of Just Energy Group Inc. used under license.',
  'Texas – PUCT License #10052, Maryland – MD Supplier License #IR-639 #IR-737.'

]

const Footer = () => (
  <Styled.Footer dark>
    <Styled.CoronaBar>
      Just Energy’s COVID-19 (Coronavirus) Update -{' '}
      <a href="https://help.justenergy.com/s/article/How-is-Just-Energy-responding-to-the-COVID-19-Coronavirus-pandemic?language=en_US">
        Texas Customers
      </a>{' '}
      - <Link to="/covid-19-alberta">Alberta Customers</Link>{' '}
      - <Link to="/covid-19-ontario">Ontario Customers</Link>
    </Styled.CoronaBar>
    <Styled.TopSection dark>
      <Link to="/" className="logo">
        <img src={secondaryLogo} alt="" />
      </Link>
    </Styled.TopSection>
    <Styled.BottomSection>
      {footerNavItems.map(({ label, navItems }) => (
        <Styled.Nav>
          <Typography variant="feature">{label}</Typography>
          {navItems.map(({ href, text }) => (
            <Link to={href}>{text}</Link>
          ))}
        </Styled.Nav>
      ))}
      <div>
        <Typography variant="feature">Stay Connected</Typography>
        <Styled.SocialLinks>
          <a
            href="https://www.linkedin.com/company/just-energy_2/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedIn} alt="LinkedIn Icon" />
          </a>
          <a
            href="https://www.youtube.com/user/JustEnergyGroup"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youTube} alt="Youtube Icon" />
          </a>
          <a
            href="https://twitter.com/JustEnergyus"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} alt="Twitter Icon" />
          </a>
          <a
            href="https://www.facebook.com/JustEnergyUS/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={faceBook} alt="Facebook Icon" />
          </a>
        </Styled.SocialLinks>
        <div>
          <a
            href="https://play.google.com/store/apps/details?id=com.energy.advisor"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googlePlay} alt="Google play store logo" />
          </a>
        </div>
        <div>
          <a
            href="https://apps.apple.com/app/id1139090493"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={appStore} alt="Apple app store logo" />
          </a>
        </div>
      </div>
    </Styled.BottomSection>
    <Styled.ExtraLinks>
      <Styled.Nav>
        {additionalFooterLinks.map(({ href, text }) => (
          <Link key={text} to={href}>
            {text}
          </Link>
        ))}
      </Styled.Nav>
    </Styled.ExtraLinks>
    {disclaimers && (
      <Styled.LegalSection dark>
        {disclaimers.map(disclaimer => (
          <Typography variant="legal">{disclaimer}</Typography>
        ))}
      </Styled.LegalSection>
    )}
  </Styled.Footer>
)

export default Footer
