import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import _get from 'lodash.get'

// eslint-disable-next-line import/no-named-default
import { default as CorePhoneCTA } from './PhoneCTA/index'

const PhoneCTA = ({ children, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phoneNumber
        }
      }
    }
  `)
  const phoneNumber = _get(data, 'site.siteMetadata.phoneNumber')
  return (
    <CorePhoneCTA phoneNumber={phoneNumber} {...props}>
      {children}
    </CorePhoneCTA>
  )
}

PhoneCTA.propTypes = {
  children: PropTypes.string.isRequired,
}

export default PhoneCTA
