/**
 * Send events to Google Analytics
 * @param {string} category - The category of the event.
 * @param {string} action - The type of action that triggers this event.
 * @param {string} label - The lable for the event.
 */
export const gaEventTracker = (category, action, label) => {
    const payload = {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    };
    window.ga('send', payload);
}

/**
 * Get the page title
 */
export const getPageTitle = () => {
    try {
        const titleTagContent = document.getElementsByTagName("title")[0].innerText;
        const pageTitle = titleTagContent.match(/^(.*?)\s\|/)[1]
        return pageTitle;
    } catch (error) {
        return "JE";
    }
    
}