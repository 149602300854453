import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { stripUnit, rem } from 'polished'

export const Button = styled.button(
  ({
    color,
    theme,
    rounded,
    shadow,
    variant,
    outlined,
    leftIcon,
    rightIcon,
  }) => {
    let borderRadius
    let colorAdjustment = 'dark'

    if (rounded === 'min') {
      borderRadius = theme.radius
    } else if (rounded === 'max') {
      borderRadius = '2em'
    } else {
      borderRadius = 'none'
    }

    if (!theme.colors[color].dark) {
      colorAdjustment = 'light'
    }

    return css`
      margin-right: ${theme.spacing.xxs};
      padding: ${theme.spacing.xxs} ${theme.spacing.sm};
      text-decoration: none;
      font: inherit;
      color: ${color ? theme.colors[color].base.readable : '#fff'};
      background-color: ${theme.colors[color].base.hex};
      border: 0;
      border-radius: ${borderRadius};
      cursor: pointer;
      transition: all 0.3s;
      -webkit-appearance: button;

      /* Without this span, chrome won't fire a click event until the second click */
      span {
        pointer-events: none;
      }

      &:hover {
        color: ${theme.colors[color][colorAdjustment].readable};
        background-color: ${theme.colors[color][colorAdjustment].hex};
      }

      &:active {
        color: ${theme.colors[color][`${colorAdjustment}er`].readable};
        background-color: ${theme.colors[color][`${colorAdjustment}er`].hex};
      }

      ${leftIcon &&
        `
          padding-right: ${theme.spacing.md};
        `}

      ${rightIcon &&
        `
          padding-right: ${theme.spacing.xs};
        `}

      ${shadow && `box-shadow: ${theme.shadow}`};

      ${variant === 'small' &&
        `padding: ${theme.spacing.min} ${theme.spacing.xxs}`};

      ${variant === 'feature' &&
        `
        padding-top: ${theme.spacing.xs};
        padding-bottom: ${theme.spacing.xs};
        `}

      ${outlined &&
        `
        background-color: transparent;
        color: ${theme.colors[color].base.hex};
        border: 2px solid ${theme.colors[color].base.hex};

        &:hover {
          background-color: transparent;
          color: ${theme.colors[color][colorAdjustment].hex};
          border-color: ${theme.colors[color][colorAdjustment].hex};
        }

        &:active {
          background-color: transparent;
          color: ${theme.colors[color][`${colorAdjustment}er`].hex};
          border-color: ${theme.colors[color][`${colorAdjustment}er`].hex};
        }
      `};

      ${variant === 'mega' &&
        `
        padding: ${theme.spacing.sm};
        justify-self: stretch;

        svg {
          margin: 0 auto ${theme.spacing.sm};
          width: ${theme.spacing.lg};
          min-width: ${theme.spacing.lg};
          max-height: none;

          @media (min-width: ${theme.breakpoints.md}) {
            width: ${theme.spacing.xxl};
            min-width: ${theme.spacing.xxl};
          }
        }
      `}

      ${variant === 'hero' &&
        `
        padding-top: ${theme.spacing.sm};
        padding-bottom: ${theme.spacing.sm};
        padding-left: ${
          leftIcon
            ? rem(stripUnit(theme.spacing.xl) - stripUnit(theme.spacing.min))
            : theme.spacing.xl
        };
        padding-right: ${
          rightIcon
            ? rem(stripUnit(theme.spacing.xl) - stripUnit(theme.spacing.min))
            : theme.spacing.xl
        };

        svg {
          min-width: ${theme.spacing.lg};
        }
      `}
    `
  }
)

export const FlexWrapper = styled.span`
  ${({ mega }) => mega && `flex-direction: column`};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LeftIcon = styled.span`
  svg {
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }
`

export const RightIcon = styled.span`
  svg {
    margin-left: ${({ theme }) => theme.spacing.xxs};
  }
`
