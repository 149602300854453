import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../Typography'
import * as Styled from './Button.styles'

const Button = ({ children, variant, leftIcon, rightIcon, ...props }) => {
  let textVariant = null

  if (variant === 'small') {
    textVariant = 'small'
  } else if (variant === 'feature') {
    textVariant = 'feature'
  } else if (variant === 'hero') {
    textVariant = 'h5'
  }

  return (
    <Styled.Button
      variant={variant}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      {...props}
    >
      <Styled.FlexWrapper mega={Boolean(variant === 'mega')}>
        {leftIcon && <Styled.LeftIcon>{leftIcon}</Styled.LeftIcon>}
        <Typography variant={textVariant} rendersAs="span">
          {children}
        </Typography>
        {rightIcon && <Styled.RightIcon>{rightIcon}</Styled.RightIcon>}
      </Styled.FlexWrapper>
    </Styled.Button>
  )
}

Button.defaultProps = {
  rounded: 'min',
  shadow: true,
  outlined: false,
  type: 'button',
  color: 'primary',
  className: null,
  variant: null,
  leftIcon: null,
  rightIcon: null,
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['default', 'small', 'feature', 'hero', 'mega']),
  rounded: PropTypes.oneOf(['none', 'min', 'max']),
  shadow: PropTypes.bool,
  outlined: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'dark', 'light']),
  className: PropTypes.string,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
}

export default Button
